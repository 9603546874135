
@font-face {
	font-family: KalamehWebFaNum;
	font-style: normal;
	font-weight: 100;
	src: url('./Fonts/woff2/KalamehWebFaNum-Thin.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./Fonts/woff/KalamehWebFaNum-Thin.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
	font-family: KalamehWebFaNum;
	font-style: normal;
	font-weight: 200;
	src: url('./Fonts/woff2/KalamehWebFaNum-ExtraLight.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./Fonts/woff/KalamehWebFaNum-ExtraLight.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
	font-family: KalamehWebFaNum;
	font-style: normal;
	font-weight: 300;
	src: url('./Fonts/woff2/KalamehWebFaNum-Light.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./Fonts/woff/KalamehWebFaNum-Light.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
	font-family: KalamehWebFaNum;
	font-style: normal;
	font-weight: 500;
	src: url('./Fonts/woff2/KalamehWebFaNum-Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./Fonts/woff/KalamehWebFaNum-Medium.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
	font-family: KalamehWebFaNum;
	font-style: normal;
	font-weight: 600;
	src: url('./Fonts/woff2/KalamehWebFaNum-SemiBold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./Fonts/woff/KalamehWebFaNum-SemiBold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
	font-family: KalamehWebFaNum;
	font-style: normal;
	font-weight: 800;
	src: url('./Fonts/woff2/KalamehWebFaNum-ExtraBold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./Fonts/woff/KalamehWebFaNum-ExtraBold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
	font-family: KalamehWebFaNum;
	font-style: normal;
	font-weight: 900;
	src: url('./Fonts/woff2/KalamehWebFaNum-Black.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./Fonts/woff/KalamehWebFaNum-Black.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
	font-family: KalamehWebFaNum;
	font-style: normal;
	font-weight: bold;
	src: url('./Fonts/woff2/KalamehWebFaNum-Bold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./Fonts/woff/KalamehWebFaNum-Bold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
	font-family: KalamehWebFaNum;
	font-style: normal;
	font-weight: normal;
	src: url('./Fonts/woff2/KalamehWebFaNum-Regular.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./Fonts/woff/KalamehWebFaNum-Regular.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
	font-family: Kanit;
	font-style: normal;
	font-weight: normal;
	src: url('./Fonts/en/Kanit-Regular.ttf') format('ttf');
}
@font-face {
	font-family: Kanit;
	font-style: normal;
	font-weight: bold;
	src: url('./Fonts/en/Kanit-Regular.ttf') format('ttf');
}

@font-face {
	font-family: Kanit;
	font-style: normal;
	font-weight: 100;
	src: url('./Fonts/en/Kanit-Thin.ttf') format('ttf');
}
@font-face {
	font-family: Kanit;
	font-style: normal;
	font-weight: 200;
	src: url('./Fonts/en/Kanit-ExtraLight.ttf') format('ttf');
}
@font-face {
	font-family: Kanit;
	font-style: normal;
	font-weight: 300;
	src: url('./Fonts/en/Kanit-Light.ttf') format('ttf');
}
@font-face {
	font-family: Kanit;
	font-style: normal;
	font-weight: 500;
	src: url('./Fonts/en/Kanit-Medium.ttf') format('ttf');
}
@font-face {
	font-family: Kanit;
	font-style: normal;
	font-weight: 600;
	src: url('./Fonts/en/Kanit-SemiBold.ttf') format('ttf');
}
@font-face {
	font-family: Kanit;
	font-style: normal;
	font-weight: 800;
	src: url('./Fonts/en/Kanit-ExtraBold.ttf') format('ttf');
}
@font-face {
	font-family: Kanit;
	font-style: normal;
	font-weight: 900;
	src: url('./Fonts/en/Kanit-Black.ttf') format('ttf');
}


:root {
  --background: #212121;
  --primary-1: #303030;
  --primary-2: #424242;
  --primary-3: #2f2f2f;
  --primary-4: #151515;
  --text-1: #ffffff;
  --text-2: #e5e5e5;
  --text-3: #236bf6;
  --text-4: #ccb3e5;
  --text-42: #64B246;
  --text-5: #000000;
  --text-6: #979797;
  --text-7: #1e7db2;
  --text-8: #64b246;
  --text-9: #f2f2f2;
  --text-10: #8040bf;
  --text-11: #c4c4c4;
  --text-12: #2e4ffd;
  --text-title-landing: #D9C6EC;
  --border-1: #edebe9;
  --border-3: #edebe9;
  --shadow-1: 0px 4px 16px rgba(0, 0, 0, 0.25);
  --shadow-2: 0px 4px 25px 3px rgba(0, 0, 0, 0.25);
  --border-2: #c8c6c4;
  --border-radius: 16px;
  --border-radius-2: 4px;
  --trans-1: all 0.2s ease-in-out;
  --fs-small: 10px;
  --fs-textfield: 16px;
  --fs-textfield-2: 16px;
  /* --shadow-2: 0 8px 15px #00000034; */
  /* --error-bg: #dff6dd; */
  /* --error-border: #107c10; */
  /* --error-bg-yellow: #fff4ce; */
  /* --error-border-yellow: #797775; */
  /* --error-bg-red: #fde7e9; */
  --error-bg: #dff6dd;
  --error-border: #107c10;
  --error-bg-yellow: #fff4ce;
  --error-border-yellow: #797775;
  --error-bg-red: #fde7e9;
  --error-border-red: #a80000;



  --n-body-1: #FAFBFF;
  --n-body-2: #ffffff;
  --n-body-3: #F2F2F2;
  --n-grey-0: #ffffff;
  --n-grey-05: #fcfcfc;
  --n-grey-1: #F9F9F9;
  --n-grey-2: #EEEEEE;
  --n-grey-3: #E5E5E5;
  --n-grey-4: #CBCBCB;
  --n-grey-5: #C4C4C4;
  --n-grey-6: #A0A0A0;
  --n-grey-7: #979797;
  --n-grey-8: #5E5E5E;
  --n-grey-9: #2F2F2F;
  --n-text-1: #2C2C2E;
  --n-text-2: #323130;
  --n-text-3: #9197B3;
  --n-text-4: #C8C6C4;
  --n-text-5: #111111;
  --n-text-6: #000000;
  --n-primary-1: #66CC00;
  --n-primary-1-o: #66CC0014;
  --n-primary-1-o2: #66CC0022;
  --n-primary-2: #29BF25;
  --n-primary-3: #3889D0;
  --n-primary-3-o: #3889D014;
  --n-primary-4: #52A300;
  --n-primary-5: #3D7A00;
  --n-primary-6: #F3FAFF;
  --n-primary-7: #3889D0;
  --n-primary-8: #ED303D;
  --n-primary-9: #201F1E;
  --n-primary-10: #7652A3;
  --n-primary-11: #64B246;
  --n-primary-12: #017DA5;
  --n-shadow-1: 11px 10px 0px 0px var(--n-text-6);
  --n-shadow-1-hover: 6px 5px 0px 0px var(--n-text-6);
  --n-shadow-2: 11px 10px 0px 0px #00000080;

  --direction: ltr;
  --font-en: Kanit, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-seri;
  --font-fa: KalamehWebFaNum, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-seri;
  --font: var(--font-en)
  
}
* {
  font-family:  var(--font);
  padding: 0;
  margin: 0;
  scroll-behavior:smooth
}
input:focus {
  outline: none;
}
body {
  background: var(--n-body-3);
  margin: 0 auto;
  font-family:  var(--font);
  color: var(--n-text-5);
  direction: var(--direction);
  font-size: 14px;
  overflow-x: hidden;
  scroll-behavior:smooth
}
input {
  font-family:  var(--font);
}
.MuiFormLabel-root {
  font-family: var(--font-en) !important;;
}
.MuiButton-root {
  font-size: 1rem !important;
  font-family: var(--font-en) !important;
}

.spacer-l {
  height: 2rem;
}
.spacer-m {
  height: 1rem;
}
.spacer-s {
  height: 0.5rem;
}

.devider-m {
  height: 1px;
  background-color: var(--border-2);
}

.shimmer-effect {
  background-image: linear-gradient(
    to right,
    var(--border-1) 0%,
    var(--body-2) 20%,
    var(--border-1) 40%,
    var(--border-1) 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 800px;
  animation: placeholderShimmer 1s ease-in-out infinite 0s backwards !important;
}

@keyframes placeholderShimmer {
  0% {
    background-position: 400px 0;
  }

  100% {
    background-position: -400px 0;
  }
}

@media screen and (min-width: 1920px) {
  :root {
    --ws--108-94-71: 108px;
    --ws--58-32-32: 58px;
    --ws--32-28-24: 32px;
    --ws--36-20-20: 36px;
    --ws--80-50-30: 80px;
    --fs--64-30-24: 64px;
    --fs--36-24-24: 36px;
    --fs--22-16-20: 22px;
    --fs--20-16-18: 20px;
    --fs--28-18-16: 28px;
    --fs--28-20-18: 28px;
    --fs--24-18-16: 24px;
    --fs--14-10-14: 14px;
    --fs--18-12-16: 18px;
    --fs--40-28-20: 40px;
    --fs--40-24-24: 40px;
    --fs--20-14-12: 20px;
    --fs--20-14-14: 20px;
    --fs--22-14-16: 22px;
    --fs--24-20-20: 24px;
    --fs--24-20-18: 24px;
    --fs--48-30-24: 48px;
    --fs--24-16-0: 24px;
    --fs--24-16-0: 24px;
    --fs--24-14-14: 24px;
    --fs--22-12-0: 22px;
    --fs--34-18-18: 34px;
    --fs--36-20-18: 36px;
    --fs--32-24-22: 32px;
    --fs--36-24-20: 36px;
    --fs--32-28-24: 32px;
    --fs--18-18-14: 18px;
    --fs--20-12-0: 20px;
    --fs--22-0-0: 22px;
    --fs--40-36-28: 40px;
    --fs--20-20-20: 20px;
    --fs--24-24-16: 24px;
    --fs--32-32-20: 32px;
    --fs--20-20-16: 20px;
  }
}
@media screen and (max-width: 1920px) {
  :root {
    --ws--108-94-71: 108px;
    --ws--58-32-32: 58px;
    --ws--32-28-24: 32px;
    --ws--80-50-30: 80px;
    --ws--36-20-20: 36px;
    --fs--64-30-24: 64px;
    --fs--36-24-24: 36px;
    --fs--22-16-20: 22px;
    --fs--28-20-18: 28px;
    --fs--20-16-18: 20px;
    --fs--28-18-16: 28px;
    --fs--24-18-16: 24px;
    --fs--14-10-14: 14px;
    --fs--18-12-16: 18px;
    --fs--40-28-20: 40px;
    --fs--36-20-18: 36px;
    --fs--40-24-24: 40px;
    --fs--20-14-12: 20px;
    --fs--20-14-14: 18px;
    --fs--22-14-16: 22px;
    --fs--24-20-20: 24px;
    --fs--24-20-18: 24px;
    --fs--48-30-24: 48px;
    --fs--24-16-0: 24px;
    --fs--24-16-0: 24px;
    --fs--24-14-14: 24px;
    --fs--22-12-0: 22px;
    --fs--34-18-18: 31px;
    --fs--32-24-22: 32px;
    --fs--36-24-20: 18px;
    --fs--36-24-20: 36px;
    --fs--32-28-24: 32px;
    --fs--18-18-14: 18px;
    --fs--20-12-0: 20px;
    --fs--22-0-0: 22px;
    --fs--40-36-28: 40px;
    --fs--20-20-20: 20px;
    --fs--24-24-16: 24px;
    --fs--32-32-20: 30px;
    --fs--20-20-16: 20px;
  }
}
@media screen and (max-width: 1750px) {
  :root {
    --ws--108-94-71: 104px;
    --ws--58-32-32: 52px;
    --ws--80-50-30: 70px;
    --ws--36-20-20: 33px;
    --ws--32-28-24: 32px;
    --fs--64-30-24: 52px;
    --fs--36-24-24: 28px;
    --fs--22-16-20: 20px;
    --fs--28-20-18: 24px;
    --fs--20-16-18: 18px;
    --fs--28-18-16: 26px;
    --fs--24-18-16: 24px;
    --fs--14-10-14: 12px;
    --fs--18-12-16: 16px;
    --fs--40-28-20: 36px;
    --fs--36-20-18: 32px;
    --fs--40-24-24: 36px;
    --fs--20-14-12: 18px;
    --fs--20-14-14: 17px;
    --fs--22-14-16: 20px;
    --fs--24-20-20: 22px;
    --fs--24-20-18: 22px;
    --fs--48-30-24: 42px;
    --fs--24-16-0: 23px;
    --fs--24-14-14: 22px;
    --fs--22-12-0: 20.5px;
    --fs--34-18-18: 24px;
    --fs--32-24-22: 30px;
    --fs--36-24-20: 33px;
    --fs--32-28-24: 30px;
    --fs--18-18-14: 18px;
    --fs--20-12-0: 17px;
    --fs--22-0-0: 18px;
    --fs--40-36-28: 38px;
    --fs--20-20-20: 19px;
    --fs--24-24-16: 23px;
    --fs--32-32-20: 28px;
    --fs--20-20-16: 19px;
  }
}
@media screen and (max-width: 1500px) {
  :root {
    --ws--108-94-71: 100px;
    --ws--80-50-30: 65px;
    --ws--58-32-32: 42px;
    --ws--32-28-24: 30px;
    --ws--36-20-20: 30px;
    --fs--64-30-24: 44px;
    --fs--36-24-24: 24px;
    --fs--22-16-20: 18px;
    --fs--18-12-16: 14px;
    --fs--20-16-18: 16px;
    --fs--28-18-16: 24px;
    --fs--28-20-18: 22px;
    --fs--24-18-16: 22px;
    --fs--14-10-14: 11px;
    --fs--40-28-20: 32px;
    --fs--36-20-18: 28px;
    --fs--40-24-24: 32px;
    --fs--20-14-12: 16px;
    --fs--20-14-14: 16px;
    --fs--22-14-16: 18px;
    --fs--24-20-20: 20px;
    --fs--24-20-18: 22px;
    --fs--48-30-24: 36px;
    --fs--24-16-0: 18px;
    --fs--24-14-14: 20px;
    --fs--22-12-0: 16px;
    --fs--34-18-18: 22px;
    --fs--32-24-22: 28px;
    --fs--36-24-20: 30px;
    --fs--32-28-24: 30px;
    --fs--18-18-14: 18px;
    --fs--20-12-0: 15px;
    --fs--22-0-0: 16px;
    --fs--40-36-28: 38px;
    --fs--20-20-20: 18px;
    --fs--24-24-16: 22px;
    --fs--32-32-20: 28px;
    --fs--20-20-16: 18px;
  }
}
@media screen and (max-width: 1250px) {
  :root {
    --ws--108-94-71: 97px;
    --ws--80-50-30: 60px;
    --ws--58-32-32: 30px;
    --ws--36-20-20: 27px;
    --ws--32-28-24: 30px;
    --fs--64-30-24: 40px;
    --fs--36-24-24: 22px;
    --fs--22-16-20: 16px;
    --fs--18-12-16: 12px;
    --fs--20-16-18: 14px;
    --fs--28-18-16: 22px;
    --fs--28-20-18: 20px;
    --fs--24-18-16: 18px;
    --fs--14-10-14: 10px;
    --fs--40-28-20: 30px;
    --fs--36-20-18: 24px;
    --fs--40-24-24: 30px;
    --fs--20-14-12: 14px;
    --fs--20-14-14: 15px;
    --fs--22-14-16: 18px;
    --fs--24-20-20: 18px;
    --fs--24-20-18: 22px;
    --fs--48-30-24: 32px;
    --fs--24-16-0: 15px;
    --fs--24-14-14: 18px;
    --fs--22-12-0: 13.7px;
    --fs--34-18-18: 18px;
    --fs--32-24-22: 26px;
    --fs--36-24-20: 28px;
    --fs--32-28-24: 30px;
    --fs--18-18-14: 18px;
    --fs--20-12-0: 13px;
    --fs--22-0-0: 14px;
    --fs--40-36-28: 38px;
    --fs--20-20-20: 17px;
    --fs--24-24-16: 18px;
    --fs--32-32-20: 26px;
    --fs--20-20-16: 17px;
  }
}
@media screen and (max-width: 1000px) {
  :root {
    --ws--108-94-71: 94px;
    --ws--80-50-30: 50px;
    --ws--58-32-32: 34px;
    --ws--36-20-20: 23px;
    --ws--32-28-24: 28px;
    --fs--64-30-24: 30px;
    --fs--36-24-24: 24px;
    --fs--22-16-20: 18px;
    --fs--20-16-18: 16px;
    --fs--28-18-16: 20px;
    --fs--28-20-18: 20px;
    --fs--24-18-16: 18px;
    --fs--14-10-14: 10px;
    --fs--18-12-16: 10px;
    --fs--40-28-20: 28px;
    --fs--36-20-18: 20px;
    --fs--40-24-24: 24px;
    --fs--20-14-12: 14px;
    --fs--20-14-14: 14px;
    --fs--22-14-16: 16px;
    --fs--24-20-20: 18px;
    --fs--24-20-18: 20px;
    --fs--48-30-24: 30px;
    --fs--24-16-0: 13px;
    --fs--24-14-14: 16px;
    --fs--34-18-18: 18px;
    --fs--22-12-0: 11px;
    --fs--32-24-22: 24px;
    --fs--36-24-20: 26px;
    --fs--32-28-24: 28px;
    --fs--18-18-14: 18px;
    --fs--20-12-0: 12px;
    --fs--40-36-28: 36px;
    --fs--20-20-20: 17px;
    --fs--24-24-16: 20px;
    --fs--32-32-20: 32px;
    --fs--20-20-16: 20px;
  }
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
@media screen and (max-width: 850px) {
  :root {
    --ws--108-94-71: 90px;
    --ws--80-50-30: 45px;
    --ws--58-32-32: 32px;
    --ws--36-20-20: 20px;
    --ws--32-28-24: 28px;
    --fs--64-30-24: 28px;
    --fs--36-24-24: 22px;
    --fs--22-16-20: 16px;
    --fs--20-16-18: 18px;
    --fs--28-18-16: 18px;
    --fs--28-20-18: 18px;
    --fs--24-18-16: 15px;
    --fs--14-10-14: 10px;
    --fs--18-12-16: 12px;
    --fs--40-28-20: 28px;
    --fs--36-20-18: 20px;
    --fs--40-24-24: 26px;
    --fs--20-14-12: 16px;
    --fs--20-14-14: 14px;
    --fs--22-14-16: 14px;
    --fs--24-20-20: 18px;
    --fs--24-20-18: 20px;
    --fs--48-30-24: 28px;
    --fs--24-16-0: 11.9px;
    --fs--24-14-14: 18px;
    --fs--22-12-0: 9px;
    --fs--32-24-22: 22px;
    --fs--36-24-20: 24px;
    --fs--32-28-24: 28px;
    --fs--18-18-14: 16px;
    --fs--20-12-0: 11px;
    --fs--40-36-28: 33px;
    --fs--20-20-20: 20px;
    --fs--24-24-16: 24px;
    --fs--32-32-20: 30px;
    --fs--20-20-16: 19px;
  }
}
@media screen and (max-width: 750px) {
  :root {
    --ws--108-94-71: 85px;
    --ws--80-50-30: 40px;
    --ws--32-28-24: 26px;
    --ws--36-20-20: 18px;
    --fs--28-20-18: 15px;
    --fs--36-24-24: 19px;
    --fs--24-18-16: 12px;
    --fs--36-24-20: 22px;
    --fs--32-28-24: 26px;
    --fs--18-18-14: 15px;
    --fs--20-12-0: 10px;
    --fs--28-18-16: 17px;
    --fs--40-36-28: 30px;
    --fs--20-20-20: 16px;
    --fs--24-24-16: 22px;
    --fs--32-32-20: 29px;
    --fs--20-20-16: 18px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --ws--108-94-71: 80px;
    --ws--80-50-30: 30px;
    --fs--64-30-24: 26px;
    --ws--32-28-24: 24px;
    --fs--36-24-24: 26px;
    --fs--22-16-20: 22px;
    --fs--20-16-18: 20px;
    --fs--28-18-16: 16px;
    --fs--28-20-18: 20px;
    --fs--28-20-18: 18px;
    --fs--24-18-16: 16px;
    --fs--14-10-14: 16px;
    --fs--18-12-16: 16px;
    --fs--40-28-20: 20px;
    --fs--36-20-18: 18px;
    --fs--40-24-24: 26px;
    --fs--20-14-12: 14px;
    --fs--20-14-14: 14px;
    --fs--22-14-16: 16px;
    --fs--24-20-20: 20px;
    --fs--24-20-18: 18px;
    --fs--24-14-14: 16px;
    --fs--48-30-24: 24px;
    --fs--34-18-18: 18px;
    --fs--32-24-22: 22px;
    --fs--36-24-20: 20px;
    --fs--32-28-24: 24px;
    --fs--18-18-14: 14px;
    --fs--40-36-28: 28px;
    --fs--20-20-20: 20px;
    --fs--24-24-16: 16px;
    --fs--32-32-20: 20px;
    --fs--20-20-16: 16px;
  }
  
}
@media screen and (max-width: 400px) {
  :root {
    --ws--80-50-30: 20px;
    --ws--108-94-71: 71px;
    --fs--64-30-24: 22px;
    --ws--32-28-24: 22px;
    --fs--36-24-20: 18px;
    --fs--36-24-24: 22px;
    --fs--22-16-20: 18px;
    --fs--18-12-16: 14px;
    --fs--28-20-18: 14px;
    --fs--20-16-18: 18px;
    --fs--28-18-16: 14px;
    --fs--24-18-16: 12px;
    --fs--14-10-14: 14px;
    --fs--40-28-20: 18px;
    --fs--36-20-18: 16px;
    --fs--40-24-24: 24px;
    --fs--20-14-12: 12px;
    --fs--20-14-14: 14px;
    --fs--22-14-16: 14px;
    --fs--24-20-20: 18px;
    --fs--24-20-18: 16px;
    --fs--48-30-24: 22px;
    --fs--24-14-14: 14px;
    --fs--34-18-18: 16px;
    --fs--32-24-22: 20px;
    --fs--32-28-24: 22px;
    --fs--18-18-14: 12px;
    --fs--40-36-28: 26px;
    --fs--20-20-20: 16px;
    --fs--24-24-16: 14px;
    --fs--32-32-20: 18px;
    --fs--20-20-16: 14px;
  }
}

.border-x {
  width: 100%;
  border: 0.7px solid #e1e0e0;
}
.border-y {
  height: 100%;
  border: 0.7px solid #e1e0e0;
}

.space-m {
  width: 100%;
  height: 2rem;
}
.space-l {
  width: 100%;
  height: 9rem;
}
